<template lang="pug">
  b-card.delivery-card
    div.text-light-secondary.mb-2.d-flex.flex-row
      | {{storeName }}
      // | {{ notGeocodeFound || order == -100? `(No se pudo encontrar coordenadas de esta ruta)` : '' }}
      // span(v-if="order >= 0").ml-2.order-number {{order}}
    h5 {{ address }}
    h5 {{ commune }}
    div.text-light-primary {{ customerName }}
    // b-button(v-if="order === -1000" @click="$emit('done')").delivery-card-change-final-route Cambiar Ruta Final
    .delivery-card-change-driver-icon.material-icons.material-icons-round(v-if="order !== -1000" @click="$emit('changeDriver')") sync_alt
    b-button(pill).delivery-card-detail-btn
      .material-icons(@click="$router.push(`/envios/${deliveryId}`)") navigate_next
    // .delivery-card-maps-icons(v-if="links && links.waze && links.googleMaps")
      .d-flex.flex-row
        .ml-auto
          a(:href="links.waze" target="_blank")
            img(src="https://image.flaticon.com/icons/png/512/732/732141.png" style="height: 3em; width: 3em;")
          span.ml-2
            a(:href="links.googleMaps" target="_blank")
              img(style="height: 3em; width: 3em;" src="https://image.flaticon.com/icons/png/512/2991/2991231.png")
</template>

<script>
import DeliveryChangeDriverForm from './DeliveryChangeDriverForm'
// import mapsLinks from '../../shared/mapsLinks'

export default {
  components: {
    DeliveryChangeDriverForm
  },
  computed: {
    links () {
      /* if (this.lat && this.lng) {
        const links = mapsLinks(this.lat, this.lng)
        return links
      } */
      return {}
    }
  },
  props: {
    storeName: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    commune: {
      type: String,
      required: true
    },
    customerName: {
      type: String,
      required: true
    },
    deliveryId: {
      type: Number,
      required: true
    },
    draggable: {
      type: Boolean,
      default: false
    },
    notGeocodeFound: {
      type: Boolean,
      default: false
    },
    order: {
      type: Number,
      default: 0
    },
    lat: {
      type: String,
      default: ''
    },
    lng: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.delivery-card {
  border: 1px solid #C3C3C3;
  position: relative;
  padding-right: 45px;
  &-change-driver-icon {
    font-size: 28px;
    color: #212529;
    position: absolute;
    top: 14px;
    right: 11px;
    cursor: pointer;
  }
  &-detail-btn {
    position: absolute;
    top: calc(50% - 12px);
    right: 14px;
    font-size: 12px !important;
    width: 24px;
    height: 24px;
    padding: 00;
    .material-icons {
      position: absolute;
      top: -1px;
      left: -1px;
    }
  }
  &-change-final-route {
    font-size: 12px !important;
  }
  &-maps-icons {
    position: absolute;
    top: calc(75% - 12px);
    right: 14px;
    font-size: 12px !important;
    width: 150px;
    height: 24px;
    padding: 00;
  }
}
.order-number {
  color: #495057;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid #3DF9DE;
  text-align: center;
  font-size: 12px;
}
</style>
