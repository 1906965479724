<template lang="pug">
  .state-count-card.sombra
    .state-count-card-icon-container(:style="`background-color: ${color}`")
      .material-icons-outlined(style="font-size: 42px;") {{icon}}
    .state-count-card-body
      div(style="font-size: 25px;") {{stateCount}}
      div(style="font-size: 15px;") {{stateName}}
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--primary)'
    },
    stateName: {
      type: String,
      default: 'estado'
    },
    stateCount: {
      type: [String, Number],
      default: ''
    },
    icon: {
      type: String,
      default: 'local_shipping'
    }
  }
}
</script>

<style lang="scss" scoped>
.state-count-card {
  display: flex;
  height: 100px;
  &-body {
    .material-icons-outlined {
      font-size: 44px !important;
    }
    font-weight: 700;
    color: #495057;
    display: flex;
    padding: 18px;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  &-icon-container {
    color: white;
    display: flex;
    width: 110px;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>
