<template lang="pug">
  b-navbar#MoblieNavbar(variant="light" toggleable="md")
    b-navbar-toggle(v-if="!goBack" target="nav_collapse")
    .material-icons(v-else @click="$router.push($route.meta.back)") arrow_back
    h4.moblie-navbar-title {{ $route.meta.title }}
    b-collapse(v-if="!goBack" is-nav id="nav_collapse")
      b-navbar-nav
        b-nav-item(:to="'/'" exact) Inicio
        b-nav-item-dropdown(:text="`${user.name} ${user.lastName}`")
          b-dropdown-item(@click="logout") Cerrar Sesión
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      title: ''
    }
  },
  methods: {
    ...mapActions(['logout'])
  },
  computed: {
    ...mapGetters(['user']),
    goBack () {
      return !!this.$route.meta.back
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../src/styles/colors.scss';
#MoblieNavbar{
  color: white;
  background: linear-gradient(to right, hsla(0, 0%, 58%, 1), hsla(0, 0%, 40%, 1));
  justify-content: start;
  align-content: center;
  min-height: 60px;
  .moblie-navbar-title {
    margin: 0;
    margin-left: 0.75rem;
  }
  .form-control {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    color: white;
  }
}
</style>
