<template lang="pug">
#Login
  .form
    b-form(@submit="onSubmit")
      center
        img(src="../../../public/Now-Logo.png" style="width: 150px")
      br
      .box-form
        .material-icons.icon account_box
        .group
          b-form-group(label-for="input-email" style="color:white")
            .title Usuario
            b-form-input.input(
              id="input-email"
              type="email"
              placeholder="Ingresa tu Mail"
              v-model="form.email"
              required
              style="color:white"
              )
      hr
      .box-form
        .material-icons.icon lock
        .group
          b-form-group(label-for="input-password" style="color:white")
            .title Contraseña
            b-form-input.input(
              id="input-password"
              type="password"
              placeholder="Ingresa tu Contraseña"
              v-model="form.password"
              style="color:white"
              required)
      hr
      center.botones
        router-link.recover(:to="{ name: 'Recover Password' }") ¿Olvidaste tu contraseña?
        b-button.boton(type="submit" style="margin-top:20px") Ingresar
</template>
<script>
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      error: ''
    }
  },
  methods: {
    ...mapActions(['login']),
    async onSubmit (event) {
      event.preventDefault()
      this.form.email.toLowerCase()
      const response = await this.login(this.form)
      if (!response) {
        this.makeDangerToast(response || 'Credenciales incorrectas')
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../../src/styles/colors.scss';
#Login{
  margin: auto;
  max-width: 320px;
  font-size: 15px;
  color: $card-bg;
  .group {
    width: 100%;
  }
  .botones {
      margin-top:40px;
    display: flex;
    flex-direction: column;
  }
  .recover {
    color: var(--light-secondary);
  }
  hr{
    background: $card-bg;
    margin-top: -10px;
  }
  .icon{
    padding: 0px;
    margin-top: 16px;
    font-size: 30px;
  }
  .title{
    color: $card-bg;
    margin-left:12px;
    font-weight: bold;
    font-size: 13px;
  }
}
</style>
