import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import LoginView from '../views/LoginView.vue'
import UsersView from '../views/UsersView'
import StoreView from '../views/StoreView'
import StoreDetailView from '../views/StoreDetailView'
import RecoverPasswordView from '../views/RecoverPasswordView'
import RecoverPasswordFormView from '../views/RecoverPasswordFormView'
import DeliveryView from '../views/DeliveryView'
import DeliveryDriverDetailView from '../views/DeliveryDriverDetailView'
import DeliveryDetailView from '../views/DeliveryDetailView'
import ExceptionView from '../views/ExceptionView'
import CreateDriverDeliveryView from '../views/CreateDriverDeliveryView'
import CreateWithdrawalView from '../views/CreateWithDrawalView.vue'
import WithdrawalView from '../views/WithdrawalView.vue'

Vue.use(VueRouter)

const userData = JSON.parse(localStorage.getItem('user'))
const user = userData ? userData.user : null

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isPublic: false,
      roles: ['admin', 'delivery', 'store'],
      title: user && user.role === 'delivery' ? 'Lista de Envíos' : 'Inicio'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/usuarios',
    name: 'Users',
    component: UsersView,
    meta: {
      isPublic: false,
      roles: ['admin']
    }
  },
  {
    path: '/tiendas',
    name: 'Stores',
    component: StoreView,
    meta: {
      isPublic: false,
      roles: ['admin']
    }
  },
  {
    path: '/excepciones',
    name: 'Exceptions',
    component: ExceptionView,
    meta: {
      isPublic: false,
      roles: ['admin']
    }
  },
  {
    path: '/tiendas/:id',
    name: 'StoreDetail',
    component: StoreDetailView,
    meta: {
      isPublic: false,
      roles: ['admin']
    }
  },
  {
    path: '/recuperar-password',
    name: 'Recover Password',
    component: RecoverPasswordView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/recuperar-password-form/:token',
    name: 'Recover Password Form',
    component: RecoverPasswordFormView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/envios',
    name: 'Delivery',
    component: DeliveryView,
    meta: {
      isPublic: false,
      roles: ['admin', 'store']
    }
  },
  {
    path: '/envios/:id',
    name: 'DeliveryDriverDetail',
    component: DeliveryDriverDetailView,
    meta: {
      isPublic: false,
      roles: ['admin', 'delivery'],
      back: { name: 'Home' },
      title: 'Estado Envío'
    }
  },
  {
    path: '/crear-envio-rapido',
    name: 'CreateDriverDelivery',
    component: CreateDriverDeliveryView,
    meta: {
      isPublic: false,
      back: { name: 'Home' },
      title: 'Crear Envío Rapido',
      roles: ['delivery']
    }
  },
  {
    path: '/envio/:id',
    name: 'DeliveryDetailView',
    component: DeliveryDetailView,
    meta: {
      isPublic: false,
      roles: ['admin', 'store']
    }
  },
  {
    path: '/crear-retiro',
    name: 'CreateWithdrawalView',
    component: CreateWithdrawalView,
    meta: {
      isPublic: false,
      roles: ['store']
    }
  },
  {
    path: '/retiros',
    name: 'WithdrawalView',
    component: WithdrawalView,
    meta: {
      isPublic: false,
      roles: ['admin']
    }
  }
]
const router = new VueRouter({
  routes
})

export default router
