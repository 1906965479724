import axios from '../../plugins/axios'

export const listStore = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` }, params: payload ? payload.params : null }
  commit('SET_STORES', { stores: [] })
  return axios.get('/store', config)
    .then((response) => {
      commit('SET_STORES', { stores: response.data.stores })
      return response
    })
    .catch(err => err.response)
}

export const getStore = ({ commit }, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  commit('SET_STORE', { store: null })
  return axios.get(`/store/${payload.id}`, config)
    .then((response) => {
      commit('SET_STORE', { store: response.data })
      return response
    })
    .catch(err => err.response)
}

export const createStore = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.post('/store', payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const deleteStore = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.delete(`/store/${payload.id}`, config)
    .then(response => response)
    .catch(err => err.response)
}

export const updateStore = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put(`/store/${payload.id}`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}
