<template lang="pug">
div
  .row.mb-4.mt-2(v-if="user.role === 'store' && checkRestrictedDays()")
    .col.d-flex.flex-column
      //- .mr-2.flex-row.d-flex(v-if="")
      //-   .material-icons.icon-color.mr-2 sentiment_very_dissatisfied
      //-   span.d-block.mt-1 Lamentablemente el dia de mañana no podremos realizar retiros
      .mt-2.flex-row.d-flex()
        .material-icons.icon-color.mr-2 watch_later
        span Actualmente no podemos crear envíos en este horario. Podrás crear envíos nuevamente el domingo a partir de las 12:01 Hrs.
      //- .mt-2.flex-row.d-flex(v-if="isException && exceptions.length > 0")
      //-   .material-icons.icon-color.mr-2 info
      //-   span(v-if="!noExceptionAllowed") {{exceptions[0].comment}}
      //-   div(style="font-weight:bold; text-align: center;").d-flex.flex-column(v-if="noExceptionAllowed")
      //-     span De momento no tenemos ruta para la fecha seleccionada.
      //-     span Nuestra atención por Instagram es de lunes a viernes de 9 a 23 horas.
      //-     span Nuestras rutas son de Martes a Sabádo.
  b-form(v-if="user.role === 'store' ? !checkRestrictedDays() : true" @submit.stop.prevent="onSubmit")
    .box-form
      .material-icons.icon-input perm_identity
      b-form-group(
        label-for="customer-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Cliente
        b-form-input#customer-input.input(
          v-model="form.customerName"
          maxlength="150"
          :state="$v.$dirty ? !$v.form.customerName.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input perm_identity
      b-form-group(
        label-for="customer-phone-input"
      )
        .input-title Teléfono Cliente
        b-form-input#customer-phone-input.input(
          v-model="form.customerPhone"
          :formatter="phoneNumberFormatter"
        )
    hr.linea
    .box-form(v-if="user.role!=='store' && !driverId")
      .material-icons.icon-input perm_identity
      b-form-group(
        label-for="driver-input"
      )
        .input-title Conductor
        b-form-select#driver-input.input(
          v-model="form.driverId"
          :options="driverOptions"
        )
    hr.linea(v-if="user.role!=='store' && !driverId")
    .box-form(v-if="user.role!=='store'")
      .material-icons.icon-input store
      b-form-group(
        label-for="store-input"
      )
        .input-title Tienda
        v-select#store-input(
          v-model="form.storeId"
          :reduce="store => store.value"
          :options="storeOptions"
        )
    .box-form(v-if="!date && user.role!=='store'")
      .material-icons.icon-input calendar_today
      b-form-group(
        label-for="date-input"
        invalid-feedback="Este campo es requerido"
      )
        .input-title Fecha
        b-form-input#date-input.input(
          v-model="form.date"
          type="date"
          :state="$v.$dirty ? !$v.form.date.$invalid : null"
        )
    hr.linea(v-if="user.role!=='store' && !date")
    .box-form
      .material-icons.icon-input place
      b-form-group(
        label-for="commune-input"
      )
        .input-title Comuna
        b-form-input#commune-input.input(
          v-model="form.address.commune"
          maxlength="256"
        )
    hr.linea
    .box-form
      .material-icons.icon-input place
      b-form-group(
        label-for="street-input"
      )
        .input-title Dirección
        b-form-input#street-input.input(
          v-model="form.address.street"
          maxlength="256"
        )
    hr.linea
    .text-right
    div(v-if="editMode")
      .box-form
        .material-icons.icon-input place
        b-form-group(
          label-for="state-input"
        )
          .input-title Estado
          b-form-select#state-input.input(v-model="form.state")
            b-form-select-option(:value="null") Por entregar
            b-form-select-option(:value="'entregado'") Entregado
            b-form-select-option(:value="'pendiente'") Pendiente
            b-form-select-option(:value="'rechazado'") Rechazado
      hr.linea
      .box-form
        .material-icons.icon-input place
        b-form-group(
          label-for="state-input"
        )
          .input-title Comentario
          b-form-textarea#commentary-input.input(
            v-model="form.commentary"
            maxlength="256"
          )
      hr.linea
    b-form-checkbox.ml-2(v-if="user.role==='admin' && !noIncudeHide" v-model="form.hide") Ocultar este envío
    .text-right
      b-button.boton(type="submit" :disabled="loading") Guardar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'
import { required } from 'vuelidate/lib/validators'
import phoneNumberFormatter from '../../shared/phoneNumberFormatter'

export default {
  mixins: [MakeToastMixin],
  props: {
    delivery: {
      type: Object,
      default: null
    },
    editMode: Boolean,
    driverId: Number,
    noIncudeHide: Boolean,
    date: [Date, String]
  },
  data () {
    return {
      loading: false,
      isException: false,
      noExceptionAllowed: false,
      form: {
        customerName: this.editMode ? this.delivery.customerName : '',
        customerPhone: this.editMode ? this.delivery.customerPhone : '',
        driverId: this.driverId ? this.driverId : (this.editMode ? this.delivery.driverId : null),
        storeId: this.editMode ? this.delivery.storeId : null,
        date: this.date ? this.date : (this.editMode ? this.$moment(this.delivery.date.substring(0, 10)).format('YYYY-MM-DD') : ''),
        state: this.editMode ? this.delivery.state : null,
        commentary: this.editMode ? this.delivery.commentary : '',
        hide: this.editMode ? this.delivery.hide : false,
        address: {
          commune: this.editMode ? this.delivery.address.commune : '',
          street: this.editMode ? this.delivery.address.street : ''
        }
      }
    }
  },
  validations () {
    const form = {
      customerName: { required },
      date: { required }
    }
    return { form }
  },
  methods: {
    ...mapActions(['createDelivery', 'updateDelivery', 'listUser', 'listStore', 'checkWithdrawalHour', 'listExceptions']),
    phoneNumberFormatter,
    async onSubmit () {
      this.loading = true
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        if (this.editMode) {
          await this.onUpdateDelivery()
        } else {
          await this.onCreateDelivery()
        }
      }
      this.loading = false
    },
    async onCreateDelivery () {
      const response = await this.createDelivery({ data: this.form })
      if (response.status === 201) {
        this.makeSuccessToast('El envío se creó correctamente')
        this.$emit('done')
        if (this.$route.meta.back) {
          this.$router.push(this.$route.meta.back)
        }
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al crear el envío')
      }
    },
    async onUpdateDelivery () {
      const data = { ...this.form }
      const response = await this.updateDelivery({ id: this.delivery.id, data })
      if (response.status === 200) {
        this.makeSuccessToast('El envío se editó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al editar el envío')
      }
    },
    checkRestrictedDays () {
      /* *** Reglas de Tiendas para crear envíos ***

        El horario para crear envíos, para el mismo día, es de 00:00 hasta las 12:00 Horas.
        A partir de las 12:01 Horas el envío se creará para el día siguiente.

      */
      const currentDate = this.$moment.tz('America/Santiago')
      const hours = currentDate.hours()
      const minutes = currentDate.minutes()
      const day = currentDate.day()
      /*
        Los días sábados a partir de las 12:01 hrs hasta las 12:00 horas del día domingo no se podrá agendar
        en dias UTC el sábado es 6 y el Domingo es 0
      */
      const saturday = (day === 6 && hours === 12 && minutes >= 1) || (day === 6 && hours > 12)
      const sunday = (day === 0 && hours === 12 && minutes === 0) || (day === 0 && hours < 12)

      return saturday || sunday
    },
    getDeliveryDate () {
      const currentDate = this.$moment.tz('America/Santiago')
      const hours = currentDate.hours()
      const minutes = currentDate.minutes()

      console.log(this.checkRestrictedDays(), 'Horario Restringido')
      if (Number(hours) >= 12) {
        if (Number(hours) === 12 && Number(minutes) === 0) {
          return currentDate
        }
        const tomorrow = currentDate.add(1, 'days')
        // console.log('El delivery se enviará mañana: ', tomorrow.format('DD/MM/YYYY'))
        return tomorrow
        // return currentDate.format('YYYY-MM-DD')
      } else {
        // console.log('El delivery se enviará hoy: ', currentDate.format('DD/MM/YYYY'))
        return currentDate
      }
    }
  },
  computed: {
    ...mapGetters(['stores', 'users', 'user', 'exceptions']),
    storeOptions () {
      const stores = []
      stores.push({ value: null, label: 'Selecciona una Tienda' })
      this.stores.forEach(store => {
        stores.push({ value: store.id, label: store.name })
      })
      return stores
    },
    driverOptions () {
      const users = []
      const drivers = this.users.filter(user => user.role === 'delivery')
      drivers.forEach(user => {
        users.push({ value: user.id, text: user.name + ' ' + user.lastName })
      })
      return users
    }
  },
  async created () {
    if (this.user.role === 'admin') {
      if (!this.driverId) {
        this.listUser()
      }
      this.listStore()
    }
    if (this.user.role === 'store') {
      this.form.storeId = this.user.storeId
      this.form.date = this.getDeliveryDate().format('YYYY-MM-DD')
    }
    if (this.user.role === 'delivery') {
      if (!this.driverId) {
        this.listUser()
      }
      this.listStore()
    }
  }
}
</script>
