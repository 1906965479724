<template lang="pug">
  #Home.h-25
    home-driver(v-if="user.role === 'delivery'")
    b-container.mt-5(v-else-if="user.role === 'store'")
      list-store-delivery
    b-container.h-100(v-else)
      b-row.h-100(align-v="end")
        b-col.text-center
          h2.text-primary Tu entrega es Now!
</template>

<script>
import { mapGetters } from 'vuex'
import HomeDriver from '../components/home/HomeDriver'
import ListStoreDelivery from '../components/delivery/ListStoreDelivery'

export default {
  components: {
    HomeDriver,
    ListStoreDelivery
  },
  computed: {
    ...mapGetters(['user', 'deliveries'])
  }
}
</script>
