<template lang="pug">
#NavBar
  .nav
      b-container
        img.logo(src="../../../public/Now-Logo.png")
        b-navbar.bar.sombra(variant="light" toggleable="md")
          b-navbar-toggle(target="nav_collapse")
          b-collapse(is-nav id="nav_collapse")
            b-navbar-nav
              .link
                .material-icons-outlined {{ user.role === 'store' ? 'local_shipping' : 'home' }}
                b-nav-item(:to="'/'" exact) {{ user.role === 'store' ? 'Envíos' : 'Inicio' }}
              .link(v-if="user.role==='admin'")
                .material-icons-outlined admin_panel_settings
                b-nav-item(:to="'/usuarios'") Adm.Usuarios
              .link(v-if="user.role==='admin'")
                .material-icons-outlined store
                b-nav-item(:to="'/tiendas'") Tiendas
              .link(v-if="user.role==='store'")
                .material-icons-outlined schedule
                b-nav-item(:to="'/crear-retiro'") Programar Retiro
              .link(v-if="['admin'].includes(user.role)")
                .material-icons-outlined local_shipping
                b-nav-item(:to="'/envios'") Envíos
              .link(v-if="['admin'].includes(user.role)")
                .material-icons-outlined pending_actions
                b-nav-item(:to="'/retiros'") Retiros
              .link(v-if="['admin'].includes(user.role)")
                .material-icons-outlined report
                b-nav-item(:to="'/excepciones'") Excepciones
            b-navbar-nav.ml-auto
              a.mt-1.mr-2(href="https://www.instagram.com/envios.now/" target="_blank")
                img(src="../../../public/img/instagram.png" style="height: 25px")
              .link
                .material-icons person
                b-nav-item-dropdown.link(right)
                  template(v-slot:button-content)
                    span {{fullName}}
                  b-dropdown-item(@click="logout")
                    span.sesion-item  Cerrar Sesión
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      fullName: {}
    }
  },
  created () {
    this.getUser()
  },
  methods: {
    ...mapActions(['logout']),
    getUser () {
      const user = JSON.parse(localStorage.getItem('user')).user
      this.fullName = `${user.name} ${user.lastName}`
    },
    checkRole (roles) {
      return roles.includes(this.user.role)
    }
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../../src/styles/colors.scss';

#NavBar{
  background:linear-gradient(to right, hsla(0, 0%, 58%, 1), hsla(0, 0%, 40%, 1));
  height: 110px;
}
.bar {
  border-radius: 10px;
  margin-top: -9px;
  font-family:'Source Sans Pro Semi Bold', sans-serif;
  font-weight: 700;
  font-size: 15px;
  z-index: 2;
}
.logo {
  width: 80px;
  margin: 6px 0px 0px 0px;
}

.link{
  color: var(--light-primary);
  align-items: center;
  display: flex;
  margin-right: 20px;
}
</style>
