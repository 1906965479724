<template lang="pug">
  div
    delivery-state-cards.mb-4
    .tabla.sombra
      b-form.mb-2(@submit.stop.prevent="fetchData")
        b-form-row
          b-col.mb-2(cols="12" md="auto")
            h3 Envíos
          b-col.mb-2(cols="12" md="2")
            b-form-input(v-model="filterOptions.search" placeholder="buscar")
          b-col.mb-2(cols="12" md="2")
            b-form-datepicker(
              label-no-date-selected="sin fecha"
              v-model="filterOptions.date"
              :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' }"
              reset-button
            )
          b-col.mb-2(cols="12" md="2")
            v-select(v-model="filterOptions.storeId" :options="storeOptions" :reduce="store => store.value")
          b-col.mb-2(cols="12" md="2")
            b-form-select(v-model="filterOptions.driverId" :options="driverOptions")
          b-col.mb-2(cols="12" md="2")
            b-form-select(v-model="filterOptions.state" :options="stateOpations")
          b-col.mb-2(cols="12" md="auto")
            b-button(type="submit" block) Filtrar
          b-col.mb-2(cols="12" md="auto")
            b-button(v-b-modal.upload-deliveries-modal block) Importar Envíos
          //- b-col.mb-2(cols="12" md="auto")
          //-   b-button(href="PlantillaCargaMasiva.xlsx") Descargar Plantilla
          b-col.mb-2(cols="12" md="auto")
            b-button(v-b-modal.create-delivery-form-modal block) Ingresar Nuevo
          b-col.mb-2(cols="12" md="auto")
            b-button(@click="downloadExcelDelivery({ params: { ...filters } }), fetchData()" block) Exportar Excel
          b-col.mb-2(cols="12" md="auto")
            b-dropdown(text="Acciones")
              b-dropdown-item-button(v-b-modal.deliveries-change-driver-form-modal) Cambiar Conductor
              b-dropdown-item-button(@click="deleteDeliveries") Eliminar Envíos
              b-dropdown-item-button(@click="hideDeliveries") Ocultar Envíos
              b-dropdown-item-button(@click="noHideDeliveries") Desocultar Envíos
              b-dropdown-item-button(v-b-modal.deliveries-change-date-form-modal) Cambiar Fechas
              b-dropdown-item-button(@click="printSelectedPackages()") Imprimir Seleccionados
              b-dropdown-item-button(v-if="user.role==='admin'" v-b-modal.deliveries-massive-change-driver-modal) Asignar Conductor Masivo
              b-dropdown-item-button(@click="bulkPrintByDate()") Impresión Masiva Por Fecha
      b-table(
        :fields="fields" :items="deliveryData"
        responsive :busy="isBusy"
      )
        template(#table-busy)
          .text-center.text-muted.my-2
            b-spinner.align-middle.mr-2(small)
            strong Cargando...
        template(#head(select))
          b-form-checkbox(v-model="selectedAllIds" @change="toggleSelectAll")
        template(#cell(select)="{item}")
          b-form-checkbox(:value="item.id" v-model="selectedIds" @change="handlePackageToPrint(item.actions)")
        template(#cell(state)="{value}")
          div
            b-badge(v-if="value === 'Entregado'" variant="success") {{ value }}
            b-badge(v-else-if="value === 'Rechazado'" variant="danger") {{ value }}
            b-badge(v-else-if="value === 'Pendiente'" variant="warning").text-white {{ value }}
            b-badge(v-else variant="light") {{ value }}
        template(#cell(lastSendSMS)="{value, item}")
          div(style="display: flex; align-items: center; justify-content: center; min-width: 180px;")
            | {{ value ? $moment(value).format('DD-MM-YYYY HH:mm') : 'Sin enviar' }}
            | &nbsp;
            .material-icons(
              v-if="value"
              :style="item.lastSendSMSErrorMessage ? 'color: #f46a6a' : 'color: #00c5ac'"
              v-b-tooltip.hover :title="item.lastSendSMSErrorMessage || 'Enviado Correctamente'"
            ) {{ item.lastSendSMSErrorMessage ? 'error' : 'check' }}
        template(#cell(actions)="{value}")
          .botones-accion
            b-button.mr-2.mb-1.boton-editar(@click="openEditModal(value)")
              .material-icons create
            b-button.mr-2.mb-1.boton-ver(@click="$router.push(`/envio/${value.id}`)")
              .material-icons remove_red_eye
            //- b-button.mb-1.boton-imprimir(@click="printSelectedPackages()")
            b-button.mb-1.boton-imprimir(@click="printPackageLabel(value)")
              .material-icons print
            b-button.mb-1.boton-eliminar(@click="onDeleteDelivery(value)")
              .material-icons delete_outline
        template(v-slot:cell(storeName)="{item}")
          .columna {{item.storeName}}
      b-pagination-nav(
        align="center"
        :number-of-pages="totalPages? totalPages : 1"
        :link-gen="linkGen"
        v-model="currentPage"
        )
    b-modal#create-delivery-form-modal(ref="createDeliveryFormModal" hide-footer title="Creando Envío")
      delivery-form(@done="onDoneDeliveryModal")

    b-modal(ref="updateDeliveryFormModal" hide-footer :title="`Editando Envío #${selectedDelivery ? selectedDelivery.id: ''}`")
      delivery-form(v-if="selectedDelivery" @done="onDoneDeliveryModal" :delivery="selectedDelivery" :editMode="true")
    b-modal#upload-deliveries-modal(title="Importar Envíos" ok-only @hide="isExcelErrors = true")
      upload-deliveries-from-exel(ref="uploadDeliveryFromExcelForm" @errors="checkExcelErrors" @showSaveBtn="toggleSaveBtn" @done="showSaveBtn = false")
      template(#modal-footer="{close}")
        b-button(v-if="showSaveBtn" @click="$refs.uploadDeliveryFromExcelForm.onSubmit()") Guardar envíos
        b-button(@click="close()" variant="outline-primary") {{ showSaveBtn ? 'Cancelar' : 'Cerrar' }}
    b-modal#deliveries-change-driver-form-modal(ref='deliveriesChangeDriverFormModal' title="Importar Envíos" hide-footer @hide="fetchData")
      deliveries-change-driver-form(@done="$refs.deliveriesChangeDriverFormModal.hide()" :ids="selectedIds")
    b-modal#deliveries-change-date-form-modal(ref="updateDeliveriesDatesModal" title="Importar Envíos" hide-footer @hide="fetchData")
      DeliveryChangeDateForm(@done="$refs.updateDeliveriesDatesModal.hide()" :ids="selectedIds")
    b-modal#deliveries-massive-change-driver-modal(ref='deliveriesMassiveChangeDriverModal' title="Asignar Conductor Masivo" ok-only=true ok-title="Cancelar" @hide="fetchData, deliveriesEmpty = true")
      deliveries-massive-change-driver(ref="deliveriesMassiveChangeDriverForm" @done="$refs.deliveriesMassiveChangeDriverModal.hide()" @checkDeliveries="checkDeliveries" :ids="selectedIds")
      template(#modal-footer="{close}")
        b-button(@click="$refs.deliveriesMassiveChangeDriverForm.onSubmit()" :disabled="deliveriesEmpty") Enviar
        b-button(@click="close()" variant="outline-primary") Cancelar

    PrintAllPackagesLabel(ref="allPackagesLabelTemplate")
</template>
<script>
import DeliveryForm from './DeliveryForm'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'
import UploadDeliveriesFromExel from './UploadDeliveriesFromExel'
import DeliveriesChangeDriverForm from './DeliveriesChangeDriverForm'
import BulkDeleteDeliveryMixin from './mixins/BulkDeleteDeliveryMixin'
import BulkUpdateDeliveryMixin from './mixins/BulkUpdateDeliveryMixin'
import DeliveryStateCards from './DeliveryStateCards'
import DeliveryChangeDateForm from './DeliveryChangeDateForm'
import PrintAllPackagesLabel from './printPackages/PrintAllPackagesLabel.vue'
import DeliveriesMassiveChangeDriver from './DeliveriesMassiveChangeDriver.vue'

export default {
  mixins: [MakeToastMixin, BulkDeleteDeliveryMixin, BulkUpdateDeliveryMixin],
  props: {
    deliveryList: Array
  },
  components: {
    DeliveryForm,
    UploadDeliveriesFromExel,
    DeliveriesChangeDriverForm,
    DeliveryStateCards,
    DeliveryChangeDateForm,
    PrintAllPackagesLabel,
    DeliveriesMassiveChangeDriver
  },
  data () {
    return {
      currentPage: 1,
      selectedDelivery: null,
      ancho: 0,
      totalPages: null,
      selectedIds: [],
      packages: [],
      isBusy: false,
      selectedPackages: [],
      selectedAllIds: false,
      isExcelErrors: true,
      deliveriesEmpty: true,
      showSaveBtn: false,
      fetchDebounce: null,
      stateOpations: [
        { text: 'Estado', value: null },
        { value: 'NULL', text: 'Por entregar' },
        { value: 'entregado', text: 'Entregado' },
        { value: 'pendiente', text: 'Pendiente' },
        { value: 'rechazado', text: 'Rechazado' }
      ],
      filterOptions: {
        state: null,
        date: null,
        storeId: null,
        driverId: null,
        search: '',
        page: 0,
        size: 10
      },
      fields: [
        { key: 'select' },
        { label: 'Id', key: 'id' },
        { label: 'Nombre Tienda', key: 'storeName' },
        { label: 'Cliente', key: 'customerName' },
        { label: 'Conductor', key: 'driverName' },
        { label: 'Dirección', key: 'address' },
        { label: 'Comuna', key: 'commune' },
        { label: 'Fecha', key: 'date' },
        { label: 'Teléfono Cliente', key: 'customerPhone' },
        { label: 'SMS', key: 'lastSendSMS' },
        { label: 'Estado', key: 'state' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listDelivery', 'deleteDelivery', 'listUser', 'listStore', 'getCountStates', 'downloadExcelDelivery']),
    toggleSelectAll (value) {
      if (value) {
        this.selectedIds = this.allDeliveryIds
        this.selectedPackages = [...this.deliveries]
      } else {
        this.selectedIds = []
        this.selectedPackages = []
      }
    },
    toggleSaveBtn (value) {
      this.showSaveBtn = value
    },
    toggleBusy () {
      this.isBusy = !this.isBusy
    },
    async deleteDeliveries () {
      const response = await this.onBulkDeleteDeliveryMixin({ deliveriesIds: this.selectedIds })
      if (response && response.ok) {
        this.fetchData()
        this.toggleSelectAll(false)
      }
    },
    async hideDeliveries () {
      const response = await this.onBulkUpdateDeliveryMixin({ deliveriesIds: this.selectedIds, hide: true })
      if (response && response.ok) {
        this.fetchData()
        this.toggleSelectAll(false)
      }
    },
    checkExcelErrors (value) {
      this.isExcelErrors = value
    },
    async noHideDeliveries () {
      const response = await this.onBulkUpdateDeliveryMixin({ deliveriesIds: this.selectedIds, hide: false })
      if (response && response.ok) {
        this.fetchData()
        this.toggleSelectAll(false)
      }
    },
    checkDeliveries (value) {
      this.deliveriesEmpty = value
    },
    handlePackageToPrint (item) {
      // console.log(item)
      // console.log(item, 'item para imprimir', checked, 'value')
      const filtered = this.selectedPackages.filter((p) => p.id === item.id)
      if (filtered.length) {
        this.selectedPackages = this.selectedPackages.filter((p) => p.id !== item.id)
      } else {
        this.selectedPackages.push(item)
      }
      // console.log(this.selectedPackages, 'paquetes para imprimir')
    },
    printSelectedPackages () {
      const selectedPackages = this.selectedPackages
      if (selectedPackages.length === 0) {
        return this.$bvToast.toast('No hay envíos seleccionados', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
      const packagesContext = selectedPackages.map(p => this.getLabelContext(p))
      this.$refs.allPackagesLabelTemplate.print(packagesContext)
    },
    async bulkPrintByDate () {
      if (!this.filters.date) {
        return this.$bvToast.toast('Debe seleccionar una fecha', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }

      await this.listDelivery({ params: { ...this.filters, size: 300 } })
      this.selectedPackages = [...this.deliveries]
      const selectedPackages = this.selectedPackages
      if (selectedPackages.length === 0) {
        return this.$bvToast.toast('No hay envíos para la fecha seleccionada', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
      const packagesContext = selectedPackages.map(p => this.getLabelContext(p))
      this.$refs.allPackagesLabelTemplate.print(packagesContext)
      // this.selectedAllIds = false
      // this.toggleSelectAll(false)
      this.fetchData()
    },
    printPackageLabel (item) {
      this.$refs.allPackagesLabelTemplate.print([this.getLabelContext(item)])
    },
    getLabelContext (item) {
      // console.log(item, 'este es el objeto a imprimir')
      return {
        id: item.id,
        customerName: item.customerName,
        customerPhone: item.customerPhone,
        customerAddress: item.address ? item.address.street : '',
        customerFullAddress: item.address ? `${item.address.street}, ${item.address.commune}` : '',
        commentary: item.commentary,
        commune: item.address ? item.address.commune : '',
        storeName: item.store ? item.store.name : '',
        storeAddressStreet: item.store ? item.store.address.street : '',
        storeAddressStreetNumber: item.store ? item.store.address.streetNumber : '',
        storeAddressApartment: item.store ? item.store.address.apartment : '',
        storeAddressCommune: item.store ? item.store.address.commune : '',
        contactAccountName: item.store ? item.store.contactAccountName : ''
      }
    },
    linkGen (pageNum) {
      return {
        path: '/envios/',
        query: { page: pageNum }
      }
    },
    handleResize () {
      this.ancho = window.innerWidth
    },
    onDoneDeliveryModal () {
      this.listDelivery()
      this.$refs.createDeliveryFormModal.hide()
      this.$refs.updateDeliveryFormModal.hide()
      this.$emit('done')
      this.fetchData()
    },
    async onDeleteDelivery (delivery) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eleminar el envío ${delivery.id} de la tienda${delivery.store ? ' ' + delivery.store.name : ''}?`)
      if (!ok) return

      const response = await this.deleteDelivery({ id: delivery.id })
      if (response.status === 200) {
        this.makeSuccessToast('El envío se eliminó correctamente')
        this.$emit('done')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar el envío')
      }
      await this.fetchData()
    },
    openEditModal (delivery) {
      this.selectedDelivery = delivery
      this.$refs.updateDeliveryFormModal.show()
    },
    async fetchData () {
      this.toggleBusy()
      const response = await this.listDelivery({ params: { ...this.filters } })
      this.getCountStates({ params: { ...this.filters } })
      if (response) {
        this.totalPages = response.data.totalPages
      }
      this.selectedAllIds = false
      this.toggleSelectAll(false)
      this.toggleBusy()
    }
  },
  computed: {
    ...mapGetters(['deliveries', 'drivers', 'stores', 'user']),
    deliveryData () {
      let items = []
      if (this.deliveries) {
        items = this.deliveries.reduce((acum, delivery) => acum.concat({
          id: delivery.id,
          storeName: delivery.store ? delivery.store.name : 'No encontrado',
          store: delivery.store,
          customerName: delivery.customerName,
          address: delivery.address.street,
          commune: delivery.address.commune,
          date: this.$moment(delivery.date.substring(0, 10)).format('DD-MM-YYYY'),
          customerPhone: delivery.customerPhone,
          actions: delivery,
          _rowVariant: delivery.hide ? 'primary' : undefined,
          state: delivery.state ? delivery.state.replace(/^./, delivery.state[0].toUpperCase()) : 'Por entregar',
          driverName: delivery.driver ? `${delivery.driver.name} ${delivery.driver.lastName}` : 'No encontrado',
          lastSendSMS: delivery.lastSendSMS,
          lastSendSMSErrorMessage: delivery.lastSendSMSErrorMessage
        }), [])
      }
      return items
    },
    allDeliveryIds () { return this.deliveries.map(delivery => delivery.id) },
    filters () {
      const filter = {}
      for (const key in this.filterOptions) {
        if (this.filterOptions[key] !== null && this.filterOptions[key] !== '') {
          filter[key] = this.filterOptions[key]
        }
      }
      return filter
    },
    driverOptions () {
      return this.drivers.reduce((acum, driver) => acum.concat({
        text: `${driver.name} ${driver.lastName}`,
        value: driver.id
      }), [{ text: 'Repartidor', value: null }])
    },
    storeOptions () {
      const stores = []
      stores.push({ value: null, label: 'Tienda' })
      this.stores.forEach(store => {
        stores.push({ value: store.id, label: store.name })
      })
      return stores
    }
  },
  created () {
    this.listUser()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    if (this.ancho > 650) {
      this.fetchData()
    }
    this.listStore()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    selectedIds () {
      this.selectedAllIds = this.selectedIds.length === this.allDeliveryIds.length
    },
    currentPage (valor) {
      this.toggleSelectAll(false)
      this.filterOptions.page = valor - 1
      this.fetchData()
    },
    ancho (valor) {
      if (valor <= 650) {
        if (this.filterOptions.size !== 6) {
          this.filterOptions.size = 6
          this.fetchData()
        }
      } else if (valor > 650) {
        if (this.filterOptions.size !== 10) {
          this.filterOptions.size = 10
          this.fetchData()
        }
      }
    },
    'filterOptions.search': {
      handler (value) {
        if (value.length !== 1) {
          // console.log('Valor de búsqueda', value)
          if (this.fetchDebounce) {
            clearTimeout(this.fetchDebounce)
          }
          this.fetchDebounce = setTimeout(() => {
            this.fetchData()
          }, 800)
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.botones-accion {
  display: inline-flex;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #869099;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #ced4da;
}
</style>
