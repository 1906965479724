<template lang="pug">
  #DeliveryStateCards
    b-row(cols="1" cols-md="2" cols-lg="4")
      b-col.mb-2
        state-count-card(
          stateName="Por Entregar"
          color="#53BEF8"
          icon="local_shipping"
          :stateCount="countStates['por entregar']"
        )
      b-col.mb-2
        state-count-card(
          stateName="Entregado"
          color="#00C5AC"
          icon="inventory"
          :stateCount="countStates['entregado']"
        )
      b-col.mb-2
        state-count-card(
          stateName="Pendiente"
          color="#F1B44C"
          icon="pending_actions"
          :stateCount="countStates['pendiente']"
        )
      b-col.mb-2
        state-count-card(
          stateName="Rechazado"
          color="#F46A6A"
          icon="thumb_down"
          :stateCount="countStates['rechazado']"
        )
</template>

<script>
import StateCountCard from '../UI/StateCountCard'
import { mapGetters } from 'vuex'

export default {
  components: {
    StateCountCard
  },
  computed: {
    ...mapGetters(['countStates'])
  }
}
</script>
