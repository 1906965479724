<template lang="pug">
  #RecoverPasswordForm
    b-form(@submit="onSubmit" v-if="result === false")
      center
        img(src="../../../public/Now-Logo.png" style="width: 150px")
        p(style="color:white") Introduzca su nueva contraseña
      .box-form
        .material-icons.icon lock
        .group
          b-form-group(
            label-for="input-password"
            style="color:white"
          )
            .title Nueva contraseña
            b-form-input.input(
              v-model="password"
              id="input-password"
              type="password"
              style="color:white"
            )
            b-form-invalid-feedback(:state="$v.password.required") Este campo es requerido
            b-form-invalid-feedback(:state="$v.password.minLength") La contraseña debe contener mmínimo 6 caracteres
      hr
      .box-form
        .material-icons.icon lock
        .group
          b-form-group(
            label-for="repeat-password-input"
            style="color:white"
          )
            .title Repetir contraseña
            b-form-input.input(
              v-model="repeatPassword"
              id="repeat-password-input"
              type="password"
              style="color:white"
            )
            b-form-invalid-feedback(:state="$v.repeatPassword.sameAsPassword") Las contraseñas no coinciden
      hr
      center.botones
        b-button.boton(type="submit") Guardar
    div(v-if="result === true")
      center
        p.texto Tu contraseña ha sido actualizada, haz click en "Volver" para dirigirte al Login
        router-link.recover(:to="{ name: 'Login' }") Volver
</template>
<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      token: this.$route.params.token,
      password: '',
      repeatPassword: '',
      result: false
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    repeatPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  methods: {
    ...mapActions(['changeUserPassword']),
    async onSubmit (event) {
      event.preventDefault()
      const password = this.password
      const token = this.token
      const response = await this.changeUserPassword({ password, token })
      console.log(response)
      if (response) {
        this.result = true
      } else {
        this.makeDangerToast('No se ha podido cambiar la contraseña')
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../../src/styles/colors.scss';
#RecoverPasswordForm{
  margin: auto;
  max-width: 300px;
}
.texto {
  font-size: 15px;
}
.botones {
  margin-top:40px;
  display: flex;
  flex-direction: column;
}
  hr{
    background: $card-bg;
    margin-top: -10px;
  }
  .icon{
    padding: 0px;
    margin-top: 16px;
    font-size: 30px;
    color: $card-bg;
  }
  .recover {
    color: var(--light-secondary);
  }
  .title{
    color: $card-bg;
    margin-left:12px;
    font-weight: bold;
    font-size: 13px;
  }
</style>
