export default `
.package-label-template {
  border: 3px solid black;
  height: 121mm;
  width: 85mm;
  border-radius: 20px;
  font-size: 3.9mm;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}
.logo-company {
  display: flex;
  justify-content: center;
  filter: grayscale(1) brightness(50%);
}
.commune {
  text-align: center;
  font-size: 6.5mm;
}
.logo-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9.7mm;
  font-weight: bold;
  background: #e0e0e0 solid;  
}
.text-center {
  text-align: center;
  
}
.divider {
  border-bottom: 2.5px solid black;
  width: 100%;
}
.footer {
  display: flex;
  justify-content: space-between;
  padding: 0.164cm 0.485cm;
  height: 22mm;
  overflow: hidden;
}
.footer-info {
  align-content: center;
  font-size: 4.2mm;
  font-weight: 400;
}
.footer-id {
  align-content: center;
  font-size: 3.6mm;
  font-weight: 400;
}
.company-name {
  font-size: 4.5mm;
  font-weight: 600;
}
.content {
  padding: 0.243cm 0.364cm;
}
.customer-content {
  padding: 0.1cm 0.364cm;
  height: 39mm;
  overflow: hidden;
}
.store-content {
  padding: 0.45cm 0.364cm;
  height: 25mm;
  overflow: hidden;
}
.title {
  font-size: 4.2mm;
  font-weight: 600;
  padding-left: 1.5mm;
  padding-bottom: 1mm;
}
.customer-name {
  padding-left: 4mm;
  font-size: 4.2mm;
  font-weight: 400;
}
.customer-address {
  padding-left: 4mm;
  font-size: 4mm;
  font-weight: 400;
  max-height: 14mm;
  overflow: hidden;
}
.customer-commune {
  padding: 1mm 0 1mm 4mm;
  font-size: 5mm;
  font-weight: 700;
  text-transform: uppercase;
  width: 63mm;
  text-wrap: nowrap;
  overflow: hidden;
}
.customer-phone {
  padding-left: 4mm;
  font-size: 4mm;
  font-weight: 400;
}
.header {
  padding: 0.37cm 0 0.15cm 0.364cm;
  font-size: 6mm;
  font-weight: 700;
  text-wrap: nowrap;
  width: 76.5mm;
  height: 12mm;
  overflow: hidden;
  align-content: center;
}
.store-name {
  width: 75.5mm;
  text-transform: uppercase;
  overflow: hidden;
}
.store-social-media {
  display: flex !important;
  gap: 0px !important;
  justify-content: center !important;
  align-content: center !important;
}
.store-social-media-icon {
  align-self: center;
  font-size: 4.65mm;
}
.store-social-media-name {
  padding: 0mm 1mm;
  color: #202020;
  font-weight: 540;
  font-size: 4.65mm;
  max-width: 75.5mm;
  text-wrap: nowrap;
  overflow: hidden;
}
img {
  width: 55.67mm;
  height: 55.67mm;
}
body {
  margin: 0;
}
.info {
  font-size: 3.64mm;
  font-weight: 400;
}
.info-store-name {
  padding-left: 6.3mm;
  font-size: 3.5mm;
  font-weight: 400;
}
.info-store-address {
  padding-left: 6.3mm;
  font-size: 3.5mm;
  font-weight: 400;
  max-height: 8mm;
  overflow: hidden;
}
.info-store-commune {
  padding: 1mm 0 1mm 6.3mm;
  font-size: 3.5mm;
  font-weight: 600;
  text-transform: uppercase;
}
.info-item {
  margin-bottom: 1.22mm;
}
@media print {
  body {-webkit-print-color-adjust: exact;}
  @page {
    size: 85mm 121mm;
    margin: 0;
  }
}`
