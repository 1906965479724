<template lang="pug">
.package-label-template
  //- .content.header
    //- img(src="" style="height: 9.69mm; width: 9.69mm;")
  .header.text-center
    .store-name {{ context.storeName }}
    //- .package-id ID - {{ context.id }}
    .store-social-media(v-if="context.contactAccountName")
      b-icon.store-social-media-icon(icon="instagram")
      span.store-social-media-name {{ context.contactAccountName }}
    //- .store-social-media(v-else)
      .store-social-media-name ID - {{ context.id }}
  .customer-content
    .title DESTINATARIO:
    .customer-name {{ context.customerName }}
    .customer-address {{ context.customerAddress }}
    .customer-commune {{ context.commune }}
    .customer-phone {{ context.customerPhone }}
  .divider
  .store-content
    .title OBSERVACIONES:
    //- .info-store-name {{ context.storeName }}
    //- .info-store-address {{ `${context.storeAddressStreet} ${context.storeAddressStreetNumber}` }}
    //- .info-store-address {{ context.storeAddressApartment }}
    //- .info-store-commune {{ context.storeAddressCommune }}
  .divider
  .footer
    .footer-info Gestionado por
      .company-name EnvíosNOW
      .footer-info @envíos.now
      .footer-id N-{{ context.id }}
    .logo-company
      img(:src="logo" style="height: 22mm; width: 22mm;")
</template>

<script>
import logo from '../../../../public/Now-Logo.png'

export default {
  props: {
    context: {
      type: Object,
      default: () => ({
        id: '-',
        storeName: '?',
        customerName: '-',
        customerPhone: '-',
        customerAddress: '-',
        commune: '-',
        store: {
          address: {
            apartment: '',
            street: '-',
            streetNumber: '',
            commune: '-'
          }

        },
        storeAddressStreet: '',
        storeAddressStreetNumber: '',
        storeAddressApartment: '',
        storeAddressCommune: '',
        contactAccountName: ''
      })
    }
  },
  data () {
    return {
      logo
    }
  },
  mounted () {
  }
}
</script>
