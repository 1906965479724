<template lang="pug">
  #upload-deliveries-from-exel
    .loading(v-if="loading")
      .text-center.mb-2 {{ loadingText }}
      b-spinner.m-auto
    div(v-else-if="step===1").text-center
      input.d-none(ref="inputFile" type="file" name="file" @change="setFile")
      b-button(@click="() => this.$refs.inputFile.click()") Cargar archivo
        .material-icons upload
    div(v-else-if="step===2")
      .mb-2 Se encontraron varias paginas, selecciona las que deseas ingresar y has click en Siguente
      b-form-checkbox-group(v-model="sheets" :options="sheetsOptions" stacked)
      b-button(@click="step=3") Siguente
    div(v-else-if="step===3")
      div(v-if="deliveries.length > 1") Se cargaron correctamente {{deliveries.length}} envíos para ser asignados
      div(v-if="deliveries.length === 1") Se cargó correctamente {{deliveries.length}} envío para ser asignado
      div(v-if="deliveries.length === 0" @click="step=1") No se encontraron envíos para ser asignados en este archivo, haz click aquí para reintentar
      //- div.text-danger(v-if="errorCount > 0") {{errorCount}} datos nulos
      //- b-button(v-if="deliveries.length > 0" @click="onSubmit") Enviar
    div(v-else-if="step===4")
      div {{ loadingText }}
</template>

<script>
import xlsxFile from 'read-excel-file'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      loadingText: 'Cargando archivo',
      step: 1,
      file: null,
      sheets: [],
      sheetsOptions: [],
      loading: false,
      deliveries: [],
      drivers: [],
      errorCount: 0,
      schema: {
        TIENDA: {
          prop: 'storeName',
          type: String
        },
        FECHA: {
          prop: 'date',
          type: Date
        },
        DESTINATARIO: {
          prop: 'customerName',
          type: String
        },
        DIRECCIÓN: {
          prop: 'customerAddress',
          type: String
        },
        COMUNA: {
          prop: 'customerCommune',
          type: String
        },
        TELÉFONO: {
          prop: 'customerPhone',
          type: String
        },
        CONDUCTOR: {
          prop: 'driverName',
          type: String
        },
        CÓDIGO: {
          prop: 'id',
          type: String
        },
        OBSERVACIONES: {
          prop: 'comentary',
          type: String
        },
        PROVEEDOR: {
          prop: 'supplierName',
          type: String
        },
        ESTADO: {
          prop: 'state',
          type: String
        },
        DETALLE: {
          prop: 'details',
          type: String
        }
      }
    }
  },
  methods: {
    ...mapActions(['bulkUpdateDeliveryDriver']),
    async onSubmit () {
      this.loading = true
      this.loadingText = 'Registrando los envíos'
      const response = await this.bulkUpdateDeliveryDriver({ data: this.data })
      if (response.status === 200) {
        this.makeSuccessToast('Los envíos se registraron correctamente')
        this.loadingText = 'Los envíos se registraron correctamente'
        this.$emit('done')
      } else if (response.status >= 400) {
        this.loadingText = `${response.data.message}, ${response.data.detail}` || 'Error al registrar envíos'
        // console.log(response, 'response')
        this.makeDangerToast(response.data.message || 'Error al cambiar el conductor')
      }

      this.step = 4
      this.loading = false
    },
    setFile (event) {
      this.file = event.target.files[0]
    }
  },
  computed: {
    ...mapGetters(['user']),
    data () {
      let deliveries = []
      deliveries = this.deliveries.map((d) => {
        return {
          deliveryId: d.id,
          driverName: d.driverName
        }
      })
      return {
        // drivers: ,
        deliveries
      }
    }
  },
  watch: {
    async file () {
      this.loading = true
      const sheets = await xlsxFile(this.file, { getSheets: true })
      this.sheetsOptions = sheets.reduce((acum, item) => acum.concat({
        value: item.name,
        text: item.name
      }), [])
      this.step = 2
      this.loading = false
    },
    async step () {
      if (this.step === 2 && this.sheetsOptions.length === 1) {
        this.sheets = [this.sheetsOptions[0].value]
        this.step = 3
      }
      if (this.step === 3) {
        this.loading = true
        let deliveries = []
        let count = 0
        this.loadingText = `Cargando plantilla (${count} / ${this.sheets.length})`
        for (const sheet of this.sheets) {
          const { rows, errors } = await xlsxFile(this.file, { schema: this.schema, sheet })
          deliveries = deliveries.concat(rows)
          // if (this.user.role === 'store') {
          //   deliveries = deliveries.map((d) => {
          //     return {
          //       ...d,
          //       storeName: this.user.name,
          //       storeId: this.user.storeId,
          //       date: '2024-06-18',
          //       driverName: null
          //     }
          //   })
          // }
          count++
          this.loadingText = `Cargando plantilla (${count} / ${this.sheets.length})`
          // this.errorCount += errors.length
          console.log('Se encontraron ' + errors.length + ' filas vacías')
        }
        this.deliveries = deliveries
        this.$emit('checkDeliveries', deliveries.length < 1)
        // this.drivers = deliveries.map((d) => {
        //   return {
        //     name: d.driverName
        //   }
        // })
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#upload-deliveries-from-exel {
  .loading {
    display: flex;
    justify-content: center;
    height: 200px;
    flex-direction: column;
  }
}
</style>
